/* eslint-disable no-unreachable */
import React, { useState } from "react";
import FloatingLabel from "../../../common/components/inputs/FloatingLabel";
import { useDispatch, useSelector } from "react-redux";
import {
  hideLoader,
  showLoader,
  updateUserDetail
} from "../../../slices/userSlice";
import { sessionManager } from "../../../managers/sessionManager";
import Utils from "../../../utility";
import { httpConstants } from "../../../constants";
import { buyMoreTokens, verifyUserByAuth } from "../../../services/userService";

const BuyMoreTokenPopup = ({ closeModal }) => {
  const dispatch = useDispatch();
  let userSubscriptionDetails = useSelector(
    (state) => state.user?.info?.subscriptionDetails
  );
  const [showInfoLogo, setShowInfoLogo] = useState(false);
  const [numberOfTokensBuy, setNumberOfTokensBuy] = useState(0);
  const handleBuyMoreTokens = (e) => {
    const value = e.target.value;
    // Check if the value is a number and within the allowed range (1 to 99999)
    if (value === "" || (Number(value) > 0 && value.length <= 5)) {
      setNumberOfTokensBuy(value);
    }
  };

  const purchasedMoreTokens = async () => {
    const token = String(
      sessionManager.getDataFromCookies(httpConstants.HEADERS.AUTH0_ID_TOKEN)
    );

    try {
      dispatch(showLoader());
      const response = await buyMoreTokens({
        quantity: Number(numberOfTokensBuy),
        priceId: process.env.REACT_APP_TOP_UP_PRICE_ID,
        productId: process.env.REACT_APP_TOP_UP_PRODUCT_ID
      });
      if (response) {
        if (response?.responseCode === 200) {
          const authDetails = await verifyUserByAuth(token);
          dispatch(updateUserDetail(authDetails?.responseData));
          closeModal("");
          setNumberOfTokensBuy("");
          window.location.href = `${response?.responseData?.url}`;
        } else {
          Utils.failureToastMessage(
            "An issue occurred while buying more tokens"
          );
          return;
        }
      }
    } catch (err) {
      Utils.failureToastMessage("An error occurred while buying more tokens");
    } finally {
      dispatch(hideLoader());
    }
  };
  return (
    // eslint-disable-next-line tailwindcss/migration-from-tailwind-2, tailwindcss/no-custom-classname
    <div className="fixed inset-0 z-[60] flex items-center justify-center bg-grey-23 bg-opacity-50 backdrop-blur-md">
      <div className="relative h-[610px] w-[538px] overflow-y-auto rounded-2xl bg-white p-8 font-InterRegular shadow-lg">
        <img
          src="/images/close-icon.svg"
          alt="Close"
          onClick={closeModal}
          className="absolute right-4 top-4 cursor-pointer text-grey-16 hover:text-grey-19"
        />

        <div className="-mt-2.5 mb-6 flex flex-col items-center justify-center text-center">
          <h1 className="mt-5 font-InterBold text-ft32-41.6 text-grey-24">
            Buy More Tokens
          </h1>
          <p className="mt-2 text-center font-InterRegular text-ft18-25.2 font-normal text-grey-14">
            You can purchase more individual tokens.
          </p>
        </div>
        <div className="m-auto mt-4 flex h-[157px] w-[184px] justify-center rounded-lg bg-[#021327]">
          <div className="p-2 text-center">
            <h1 className="font-bold text-white">Current Token</h1>
            <h2 className="text-[50px] font-bold text-white">
              {userSubscriptionDetails?.tokenCount || 0}
            </h2>
            <h3 className="font-normal text-white">Token expired on:</h3>
            <h4 className="pb-1 font-medium text-white">
              {userSubscriptionDetails
                ? Utils.epochTimeToLocalDateString(
                    userSubscriptionDetails?.endDate
                  )
                : "N/A"}
            </h4>
          </div>
        </div>
        <section className="m-auto flex w-[318px] justify-center">
          <div className="mt-4">
            <div className="relative flex items-center font-InterRegular text-ft16-19.36 font-normal text-grey-14">
              How many tokens do you want to buy?
              <img
                src="/images/Tooltip-icon.svg"
                alt="info"
                className="ml-1.5 size-3.2"
                onMouseOver={() => setShowInfoLogo(true)}
                onMouseOut={() => setShowInfoLogo(false)}
              />
              {showInfoLogo && (
                <div className="absolute -top-44.2 right-111px  z-20 flex translate-x-full flex-col gap-0">
                  <p className="h-auto w-50 rounded bg-black-12 px-2 py-0.5 font-InterRegular text-ft14-20 text-white ">
                    Each token will allow you to create 1 new asset (ad, social
                    post, copy). Only purchase as many tokens as you need before
                    your token reset date since all your tokens will reset on
                    that date.
                  </p>
                  <img
                    src="/images/arrowBelow.svg"
                    alt="arrow below"
                    className="-mt-0.5 ml-1.2 h-2 w-4"
                  />
                </div>
              )}
            </div>

            <FloatingLabel
              name={"100"}
              height={"h-12"}
              width="w-[318px]"
              isStar={false}
              onBoard={true}
              isSmall={true}
              maxLength={20}
              value={numberOfTokensBuy}
              onChange={handleBuyMoreTokens}
              type="number"
            />
            <div className="m-auto mt-8 flex items-center justify-between">
              <h1 className="text-grey-14">Cost</h1>
              <h2 className="font-bold text-green-12">
                {numberOfTokensBuy ? `$${numberOfTokensBuy}.00` : 0}
              </h2>
            </div>
            <div className="m-auto mt-8 flex items-center justify-between">
              <button
                onClick={closeModal}
                className="h-11.2 w-[152px] rounded-xl border-2  border-green-12 text-center text-[14px] font-bold text-green-12 "
              >
                CANCEL
              </button>
              <button
                disabled={numberOfTokensBuy ? false : true}
                onClick={purchasedMoreTokens}
                className={`h-11.2 w-[152px] ${numberOfTokensBuy ? "opacity-100" : "opacity-50"} rounded-xl bg-green-12 text-[14px] font-bold text-white`}
              >
                PURCHASE
              </button>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default BuyMoreTokenPopup;
