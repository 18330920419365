import React, { useEffect, useState } from "react";
import FloatingLabel from "../../../common/components/inputs/FloatingLabel";
import Utils from "../../../utility";
import {
  getGoogleFonts,
  uploadFile,
  updateBrandDetails
} from "../../../services/userService";
import { toast } from "react-toastify";
import rgbHex from "rgb-hex";
import { MoonLoader } from "react-spinners";
import { useSelector } from "react-redux";

const BrandDetails = ({ brandData, setBrandTab, getBrandDetail }) => {
  const userDetails = useSelector((state) => state.user);
  const [brandName, setBrandName] = useState("");

  const [websiteUrl, setWebsiteUrl] = useState("");
  const [problem, setProblem] = useState("");
  const [solution, setSolution] = useState("");
  const [tone, setTone] = useState("");
  const [competitors, setCompetitors] = useState("");
  // eslint-disable-next-line no-unused-vars
  const [headingFonts, setHeadingFonts] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [bodyFonts, setBodyFonts] = useState([]);
  const [googleFonts, setGoogleFonts] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [loading, setLoading] = useState(false);
  const [colors, setColors] = useState([
    { id: 1, value: "#581923" },
    { id: 2, value: "#C598AF" },
    { id: 3, value: "#FBC00E" }
  ]);
  const [categoriesCompetitors, setCategoriesCompetitors] = useState([
    { title: "Product Features", status: false },
    { title: "Customer Service", status: false },
    { title: "Competitive Pricing", status: false },
    { title: "Brand Reputation", status: false },
    { title: "Customization Options", status: false },
    { title: "Speed & Efficiency", status: false },
    { title: "Quality Materials", status: false },
    { title: "Expertise & Experience", status: false },
    { title: "Social Responsibility", status: false },
    { title: "Cutting-Edge Technology", status: false }
  ]);
  // eslint-disable-next-line no-unused-vars
  const [inputs, setInputs] = useState({
    heading: "",
    body: "",
    image: null
  });
  const [showProblemTooltip, setShowProblemTooltip] = useState(false);
  const [showSolutionTooltip, setShowSolutionTooltip] = useState(false);
  const [showCompetitorsTooltip, setShowCompetitorsTooltip] = useState(false);
  const [showDifferentTooltip, setShowDifferentTooltip] = useState(false);
  const uploadImage = async (file) => {
    setLoading(true);
    let [error, response] = await Utils.parseResponse(uploadFile(file));
    if (error || !response) {
      setLoading(false);
      return;
    }
    setLoading(false);
    return response?.responseData;
  };
  const hslToRgbs = (h, s, l) => {
    let r, g, b;

    if (s === 0) {
      r = g = b = l;
    } else {
      const hueToRgb = (p, q, t) => {
        if (t < 0) t += 1;
        if (t > 1) t -= 1;
        if (t < 1 / 6) return p + (q - p) * 6 * t;
        if (t < 1 / 2) return q;
        if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6;
        return p;
      };

      const q = l < 0.5 ? l * (1 + s) : l + s - l * s;
      const p = 2 * l - q;
      r = hueToRgb(p, q, h + 1 / 3);
      g = hueToRgb(p, q, h);
      b = hueToRgb(p, q, h - 1 / 3);
    }

    return `rgb(${Math.round(r * 255)}, ${Math.round(g * 255)}, ${Math.round(b * 255)})`;
  };
  const handleHexColors = (color) => {
    let hexCode = rgbHex(`${color}`);
    return `#${hexCode}`;
  };
  const handleInputChange = async (e) => {
    if (e.target.type === "file") {
      // If the input is a file input, update the image in the state

      const file = e.target.files[0];
      if (file && (file.type === "image/jpeg" || file.type === "image/png")) {
        let URL = await uploadImage(file);
        if (URL.startsWith("https")) {
          setInputs((prev) => ({ ...prev, [e.target.name]: URL }));
          toast.success("Image uploaded successfully.");
        }
      } else {
        toast.error("Image type should be jpg or png.");
      }
    } else {
      // Otherwise, handle other input changes
      // setInputs((prev) => ({ ...prev, [e.target.name]: e.target.value }));
      const { name, value } = e.target;
      setInputs((prevInputs) => ({
        ...prevInputs,
        [name]: value
      }));
    }
  };
  const getGoogleFontsList = async () => {
    let response = await getGoogleFonts({
      sort: "alpha"
    });
    if (!response) {
      return;
    }
    setGoogleFonts(response?.responseData?.items);
    return response;
  };
  const handleColorChange = (id, e) => {
    const newColor = e.target.value;
    let rgbColor;

    // Convert HEX to RGB
    if (newColor.startsWith("#")) {
      const hex = newColor.substring(1);
      rgbColor = `rgb(${parseInt(hex.substring(0, 2), 16)}, ${parseInt(hex.substring(2, 4), 16)}, ${parseInt(hex.substring(4, 6), 16)})`;
    }
    // Convert HSL to RGB
    else if (newColor.startsWith("hsl")) {
      const [h, s, l] = newColor
        .substring(newColor.indexOf("(") + 1, newColor.indexOf(")"))
        .split(",")
        .map((value) => parseFloat(value));
      rgbColor = hslToRgbs(h, s, l);
    }
    // Use RGB color as is
    else if (newColor.startsWith("rgb")) {
      rgbColor = newColor;
    } else {
      console.error("Invalid or unsupported color format:", newColor);
      return;
    }

    setColors((prev) =>
      prev.map((color) =>
        color.id === id ? { ...color, value: rgbColor } : color
      )
    );
  };
  const toggleStatus = (index) => {
    const updatedCategories = [...categoriesCompetitors];
    updatedCategories[index].status = !updatedCategories[index].status;
    setCategoriesCompetitors(updatedCategories);
  };
  useEffect(() => {
    getGoogleFontsList();
    setBrandName(brandData?.brandName);
    setWebsiteUrl(brandData?.theme?.websiteUrl);
    setProblem(brandData?.discovery?.problem);
    setSolution(brandData?.discovery?.solution);
    setTone(Utils.capitalizeFirstLetter(brandData?.discovery?.tone));
    setCompetitors(brandData?.discovery?.competitors);
    setColors([
      { id: 1, value: brandData?.theme?.brandColors[0] },
      { id: 2, value: brandData?.theme?.brandColors[1] },
      { id: 3, value: brandData?.theme?.brandColors[2] }
    ]);
    const updatedCategories = categoriesCompetitors.map((item) => {
      return {
        ...item,
        status: brandData.discovery?.values.includes(item.title) || false
      };
    });
    setCategoriesCompetitors(updatedCategories);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [brandData]);

  const [nameError, setNameError] = useState("");

  const handleProblemChange = (e) => {
    const problemText = e.target.value;

    if (problemText.length <= 250) {
      setProblem(problemText);
    }
  };

  const handleSolutionChange = (e) => {
    const solutionText = e.target.value;

    if (solutionText.length <= 250) {
      setSolution(solutionText);
    }
  };

  const handleCompetitorsChange = (e) => {
    const competitorsText = e.target.value;

    if (competitorsText.length <= 250) {
      setCompetitors(competitorsText);
    }
  };

  const handleNameChange = (e) => {
    let brandName = e.target.value;
    // brandName = brandName;
    setBrandName(brandName);
    if (!brandName) {
      setNameError("");
    } else if (brandName.length > 29) {
      setNameError("");
    } else {
      setNameError("");
      setNameError("");
    }
  };

  const updateBrand = async () => {
    if (!brandName) {
      Utils.failureToastMessage("Please fill all required fields");
      return;
    } else if (!competitors || !problem || !solution) {
      Utils.failureToastMessage("Please fill all required fields");
      return;
    } else if (!Utils.isValidWebsiteUrl(websiteUrl)) {
      Utils.failureToastMessage("Website URL is not valid");
      return;
    } else if (!inputs.heading || !inputs.body) {
      Utils.failureToastMessage(
        "Font Headings and Font Body are required fields"
      );
      return;
    }
    const allCompetitorsStatus = categoriesCompetitors.every(
      (item) => item.status === false
    );
    let toastId;
    toast.dismiss(toastId);

    if (allCompetitorsStatus) {
      Utils.failureToastMessage("Please select differentiating factors");
      return;
    }

    if (!brandName) {
      toastId = toast.error("Please fill all required fields.");
      return;
    } else if (brandName === websiteUrl) {
      toastId = toast.error("Website and Brand URL can't be the same.");
      return;
    }
    let x = userDetails?.brandList?.filter((i) => {
      BrandDetails.name !== i;
    });
    if (x?.includes(brandName)) {
      toast.error("Brand Name already exists");
      return null;
    }

    setLoading(true);
    let requestData = {
      brandName: brandName,
      theme: {
        brandColors: colors.map((color) => color.value),
        headingFont: inputs?.heading || brandData?.theme.headingFont,
        bodyFont: inputs?.body || brandData?.theme.bodyFont,
        websiteUrl: websiteUrl,
        logo: inputs?.image || brandData?.theme?.logo
      },
      businessType: brandData?.businessType,
      businessModel: brandData?.businessModel,
      discovery: {
        problem: problem,
        solution: solution,
        tone: tone?.toLowerCase(),
        competitors: competitors,
        values: categoriesCompetitors
          .filter((item) => item.status)
          .map((item) => item.title)
      }
    };
    const [error, response] = await Utils.parseResponse(
      updateBrandDetails({ id: brandData?.id, requestData })
    );
    // let toastId;
    if (error || !response) {
      toastId = toast.error("Error while updating brand");
      setLoading(false);
      return;
    }
    toastId = toast.success("Brand details updated successfully");
    getBrandDetail();
    // toast.dismiss(toastId);
    setLoading(false);
  };

  return (
    <>
      <div className="flex">
        <MoonLoader
          loading={loading}
          color="#17C471"
          cssOverride={{
            position: "absolute",
            zIndex: 1000,
            top: "45%",
            left: "40%"
          }}
        />

        {/* First Row */}
        <div className="w-[59%] p-4">
          <h2 className=" -ml-7 -mt-8.5 font-InterBold text-ft24-32 text-grey-12">
            Brand Details
          </h2>
          <div className="mt-5.5">
            {/* Brand Name */}
            <FloatingLabel
              label={"Brand Name"}
              name={"brandName"}
              onBoard={true}
              isSmall={true}
              isStar={true}
              height={"h-11.2"}
              width={"w-109"}
              value={brandName}
              onChange={(e) => {
                const inputValue = e.target.value;
                if (inputValue.length > 30) {
                  e.target.value = inputValue.substring(0, 30);
                }
                handleNameChange(e);
              }}
              // onChange={handleNameChange}
            />
            {nameError && <span style={{ color: "red" }}>{nameError}</span>}
          </div>
          <div className="mt-4">
            {/* Website URL */}
            <FloatingLabel
              label={"Website URL"}
              name={"websiteUrl"}
              onBoard={true}
              isSmall={true}
              isStar={false}
              height={"h-11.2"}
              width={"w-109"}
              value={websiteUrl}
              onChange={(e) => setWebsiteUrl(e.target.value)}
            />
          </div>
          {/* Logo and Brand Colors */}
          <div className="mt-6 flex flex-row gap-6">
            {/* Logo */}
            <div className="relative h-max">
              <div className=" flex font-InterRegular text-ft16-19.36 font-normal text-grey-14">
                Logo <></>
                <div className="absolute -top-33px left-9.5 z-20 flex flex-col gap-0 "></div>
              </div>
              <div className="relative mt-5 flex h-30.7 w-39 items-center justify-center rounded-15px shadow-9xl  ">
                <div className="absolute -right-3.5 top-[-12px] size-9 cursor-pointer rounded-full bg-white shadow-10xl">
                  <input
                    type="file"
                    accept="image/png, image/jpeg"
                    name="image"
                    id="image"
                    alt="image Picker"
                    className=" hidden"
                    onChange={(e) => handleInputChange(e)}
                  />
                  <label htmlFor="image" className="cursor-pointer ">
                    {" "}
                    <img
                      src="/images/Picker.svg"
                      alt="Picker"
                      className="size-9  pb-2.7 pl-2.7 pr-2.5  pt-2.5  "
                    />
                  </label>
                </div>
                <div className=" flex size-full items-center justify-center rounded-15px">
                  {inputs?.image !== null || brandData?.theme?.logo != " " ? (
                    <img
                      className="size-full rounded-15px object-cover"
                      src={inputs?.image || brandData?.theme?.logo}
                      alt="brand logo"
                    />
                  ) : (
                    <div className="flex size-full items-center justify-center rounded-xl bg-green-12 font-InterBold text-ft36-40 text-white">
                      {brandName.charAt(0).toUpperCase()}
                    </div>
                  )}
                </div>
              </div>
              <p className="ml-1.2 mt-3.7 font-InterRegular text-ft14-16.94 font-normal text-grey-14">
                Allowed file types: png, jpg
              </p>
            </div>
            {/* Brand Colors */}
            <div className="relative flex flex-col gap-3.5">
              <div className="flex font-InterRegular text-ft16-19.36 font-normal text-grey-14">
                Brand colors{" "}
                <img
                  src="/images/Tooltip-icon.svg"
                  alt="info"
                  className="mb-1.5 ml-1.5 size-3.2 "
                />
              </div>

              {colors.length > 0 ? (
                colors?.slice(0, 3)?.map((color, index) => {
                  return (
                    <div
                      className="flex h-12.2 w-35.7 cursor-pointer items-center gap-1 rounded-full border border-grey-9"
                      key={index}
                      onClick={() =>
                        document
                          .getElementById(`colorPicker-${color.id}`)
                          .click()
                      }
                    >
                      <div className="relative ml-2 size-8.5 cursor-pointer">
                        <svg className="absolute size-full cursor-pointer">
                          <circle
                            cx="50%"
                            cy="50%"
                            r="40%"
                            fill={
                              color?.value?.startsWith("#")
                                ? color?.value
                                : color?.value?.startsWith("rgb")
                                  ? handleHexColors(color.value)
                                  : "Unsupported"
                            }
                          ></circle>
                        </svg>
                        <input
                          type="color"
                          name=""
                          value={
                            color?.value?.startsWith("#")
                              ? color?.value
                              : color?.value?.startsWith("rgb")
                                ? handleHexColors(color.value)
                                : "Unsupported"
                          }
                          id={`colorPicker-${color.id}`}
                          className="size-full cursor-pointer border-none opacity-0"
                          onChange={(e) => handleColorChange(color.id, e)}
                        />
                      </div>

                      <p className="mb-3.7 mt-4 h-4.5 w-21.7 font-InterRegular text-ft14-16.94 text-grey-15">
                        {color?.value?.startsWith("#")
                          ? color?.value
                          : color?.value?.startsWith("rgb")
                            ? handleHexColors(color.value)
                            : "Unsupported"}
                      </p>
                    </div>
                  );
                })
              ) : (
                <div>{"No colors found"}</div>
              )}
            </div>
          </div>
          {/* Fonts */}
          <div className="relative mt-2.5 flex flex-col">
            <div className="font-InterRegular text-ft16-19.36 font-normal text-grey-14">
              <div className="flex items-center">
                Fonts{" "}
                <img
                  src="/images/Tooltip-icon.svg"
                  alt="info"
                  className="ml-1.5 size-3.2 "
                />
              </div>
              <div className="mt-5.5 flex flex-row justify-start gap-4 font-InterRegular text-ft14-20 font-normal ">
                <FloatingLabel
                  className="w-full"
                  label={"Headings"}
                  name={"heading"}
                  onChange={(e) => handleInputChange(e)}
                  value={inputs.heading || brandData?.theme?.headingFont}
                  onBoard={true}
                  isSmall={true}
                  isStar={true}
                  height={"h-11.2"}
                  width={"w-52.5"}
                  isSelect={true}
                  showSearch={true}
                  options={headingFonts?.length > 0 ? headingFonts : []}
                  googleFonts={googleFonts.length > 0 ? googleFonts : []}
                />

                <FloatingLabel
                  label={"Body"}
                  name={"body"}
                  onChange={(e) => handleInputChange(e)}
                  value={inputs.body || brandData?.theme?.bodyFont}
                  onBoard={true}
                  isSmall={true}
                  isStar={true}
                  height={"h-11.2"}
                  width={"w-52.5"}
                  isSelect={true}
                  showSearch={true}
                  options={bodyFonts?.length > 0 ? bodyFonts : []}
                  googleFonts={googleFonts.length > 0 ? googleFonts : []}
                />
              </div>
            </div>
          </div>
        </div>
        {/* Second Row */}
        <div className=" w-full p-4 2xl:w-1/2">
          <div className="mt-3">
            <div className="flex flex-col gap-7.5">
              <div className="relative flex flex-col text-center">
                <input
                  name="problem"
                  id="problem"
                  className="peer h-11.2 w-full rounded-15px border border-grey-14 px-3 text-grey-14 outline-none focus:border-green-12 focus:ring-0 2xl:w-[87.776%]"
                  value={problem}
                  onChange={handleProblemChange}
                />
                <label
                  htmlFor="problem"
                  className="absolute -top-2.5 left-5 flex items-center bg-white px-2 font-InterRegular text-ft14-20 font-normal text-grey-14 peer-focus:text-green-10"
                >
                  What is the problem the brand solves?{" "}
                  <p className="w-max text-red-11">*</p>{" "}
                  <div className="">
                    <img
                      src="/images/Tooltip-icon.svg"
                      alt="info"
                      className="mb-1.5 ml-1.5 size-3.2"
                      onMouseEnter={() => setShowProblemTooltip(true)}
                      onMouseLeave={() => setShowProblemTooltip(false)}
                    />
                    {showProblemTooltip && (
                      <p className="absolute z-100 -ml-30 h-[169px] w-full min-w-[400px] max-w-150 overflow-auto rounded bg-black-12 px-2 py-0.5 font-InterRegular text-ft14-20 text-white ">
                        {
                          <>
                            {" "}
                            Identify the core challenge your target audience
                            faces that your brand addresses. For example, if
                            your brand sells ergonomic office chairs, the
                            problem could be
                            <span className="ml-1 font-InterItalic text-ft14-20 font-semibold underline  ">
                              &apos;Traditional office chairs cause back pain
                              and discomfort during long working hours.&apos;
                            </span>{" "}
                            This insight helps AdPerfect tailor messaging that
                            resonates with your audience&apos;s needs and pain
                            points, making your ads more relatable and
                            compelling.
                          </>
                        }
                      </p>
                    )}
                  </div>
                </label>
              </div>
              <div className="relative flex flex-col text-center">
                <input
                  name="solution"
                  id="solution"
                  className="peer h-11.2 w-full rounded-15px border border-grey-14 px-3 text-grey-14 outline-none focus:border-green-12 focus:ring-0 2xl:w-[87.776%]"
                  value={solution}
                  onChange={handleSolutionChange}
                />
                <label
                  htmlFor="solution"
                  className="absolute -top-2.5 left-5 flex items-center bg-white px-2 font-InterRegular text-ft14-20 font-normal text-grey-14 peer-focus:text-green-10"
                >
                  Solution <p className="w-max text-red-11">*</p>{" "}
                  <div>
                    <img
                      src="/images/Tooltip-icon.svg"
                      alt="info"
                      className="mb-1.5 ml-1.5 size-3.2"
                      onMouseEnter={() => setShowSolutionTooltip(true)}
                      onMouseLeave={() => setShowSolutionTooltip(false)}
                    />
                    {showSolutionTooltip && (
                      <p className="absolute z-100 -ml-30 h-41 w-105.5 min-w-[400px] max-w-150 overflow-auto rounded bg-black-12 px-2 py-0.5 font-InterRegular text-ft14-20 text-white ">
                        {
                          <>
                            {" "}
                            Describe how your product or service solves the
                            problem mentioned above. For instance,
                            <span className="ml-1 font-InterItalic text-ft14-20 font-semibold underline  ">
                              &apos;Our ergonomic chairs are designed with
                              adjustable support to prevent back pain and
                              increase comfort, enabling longer and more
                              productive work sessions.&apos;
                            </span>{" "}
                            Clear articulation of your solution enables
                            AdPerfect to generate copy that highlights the
                            benefits and features of your offering, positioning
                            it as the ideal choice for your audience.
                          </>
                        }
                      </p>
                    )}
                  </div>
                </label>
              </div>

              <FloatingLabel
                className="w-full"
                label={"Tone of Voice"}
                name={"tone"}
                onChange={(e) => {
                  setTone(e.target.value);
                }}
                value={tone}
                onBoard={true}
                maxLength={250}
                isStar={true}
                isSmall={true}
                height={"h-11.2"}
                width={"w-full"}
                parentWidth={"w-full 2xl:w-[87.776%]"}
                isSelect={true}
                options={["authoritative", "friendly", "professional"]}
              />

              <div className="relative flex flex-col text-center">
                <input
                  name="competitors"
                  id="competitors"
                  className="peer h-11.2 w-full rounded-15px border border-grey-14 px-3 text-grey-14 outline-none focus:border-green-12 focus:ring-0 2xl:w-[87.776%]"
                  value={competitors}
                  onChange={handleCompetitorsChange}
                />
                <label
                  htmlFor="competitors"
                  className="absolute -top-2.5 left-5 flex items-center bg-white px-2 font-InterRegular text-ft14-20 font-normal text-grey-14 peer-focus:text-green-10"
                >
                  Main Competitors <p className="w-max text-red-11">*</p>{" "}
                  <div>
                    <img
                      src="/images/Tooltip-icon.svg"
                      alt="info"
                      className="mb-1.5 ml-1.5 size-3.2"
                      onMouseEnter={() => setShowCompetitorsTooltip(true)}
                      onMouseLeave={() => setShowCompetitorsTooltip(false)}
                    />
                    {showCompetitorsTooltip && (
                      <p className="absolute z-100  -ml-44 h-21 w-175.7 max-w-150 overflow-auto rounded bg-black-12 px-2 py-0.5 font-InterRegular text-ft14-20 text-white ">
                        {
                          <>
                            {" "}
                            List your primary competitors’ brand names. For
                            example,
                            <span className="ml-1 font-InterItalic text-ft14-20 font-semibold underline  ">
                              &apos;Herman Miller, and OfficeChairs.com &apos;
                            </span>{" "}
                            if you were in the office chair industry. This
                            information helps AdPerfect understand the market
                            landscape and differentiate your brand in the ad
                            copy by comparing or highlighting unique aspects in
                            relation to these competitors.
                          </>
                        }
                      </p>
                    )}
                  </div>
                </label>
              </div>

              <div className="mt-4 flex flex-col">
                <p className="flex font-InterRegular text-ft16-19.36  text-grey-14">
                  Differentiating Factors <p className="w-max text-red-11">*</p>{" "}
                  <div>
                    <img
                      src="/images/Tooltip-icon.svg"
                      alt="info"
                      className="mb-1.5 ml-1.5 size-3.2"
                      onMouseEnter={() => setShowDifferentTooltip(true)}
                      onMouseLeave={() => setShowDifferentTooltip(false)}
                    />
                    {showDifferentTooltip && (
                      <p className="absolute z-100 -ml-30 h-41 w-105.5 max-w-150 overflow-auto rounded bg-black-12 px-2 py-0.5 font-InterRegular text-ft14-20 text-white ">
                        {
                          <>
                            {" "}
                            Explain what sets your brand apart from others. For
                            example, if unlike our competitors, our chairs
                            feature a patented lumbar support system and come
                            with a 10-year guarantee, I would pick
                            <span className="ml-1 font-InterItalic text-ft14-20 font-semibold underline  ">
                              Product Features
                            </span>{" "}
                            and
                            <span className="ml-1 font-InterItalic text-ft14-20 font-semibold underline ">
                              Brand Reputation
                            </span>
                            . Whether it&apos;s innovation, customer service,
                            price, or quality, sharing this allows AdPerfect to
                            craft messaging that emphasizes your unique selling
                            points, making your ads more distinctive and
                            attractive.
                          </>
                        }
                      </p>
                    )}
                  </div>
                </p>
                <div className="mt-3 font-InterRegular text-ft14-20 text-grey-14">
                  <div className="grid gap-3">
                    <div className="mt-3 font-InterRegular text-ft14-20 text-grey-14">
                      <div className="grid w-fit grid-cols-2 gap-y-3">
                        {categoriesCompetitors.map((item, index) => (
                          <div
                            key={index}
                            onClick={() => toggleStatus(index)}
                            className={`max-w-fit rounded-15px border ${item.status ? "border-green-12 text-green-12" : "border-grey-14 text-grey-14"} flex cursor-pointer px-3 py-1`}
                          >
                            {item.status && (
                              <img
                                src="/images/Control.svg"
                                alt="Control"
                                className="my-auto mr-2 size-4"
                              />
                            )}
                            {item.title}
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className=" mt-3 flex justify-center gap-8">
        <button
          disabled={loading}
          onClick={updateBrand}
          className=" h-8.7 w-[165px] rounded-15px bg-green-12 font-InterBold text-ft14-19.6 text-white"
        >
          SAVE
        </button>
        <button
          disabled={loading}
          className=" h-8.7 w-37.5 rounded-15px border-2 font-InterBold text-ft14-19.6 text-grey-14"
          onClick={() => {
            setBrandTab("PROJECTS");
          }}
        >
          CANCEL
        </button>
      </div>
    </>
  );
};

export default BrandDetails;
